<template>
  <main id="mypage">
    <div :class="['container', classW100 ? 'w100' : '']">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel">
            <div class="panel-heading">
              <h2 class="pageTitle">
                クライアント一覧<span>Client List</span>
                <button type="button" class="btn btn-xs widenBT" @click="() => { classW100 = !classW100 }">
                  <i class="fa fa-arrows-h pr5" />表示幅を切り替える
                </button>
              </h2>
            </div>
            <div class="panel-body">
              <div id="clientListWrap">
                <ListUserTable ref="listUser" />
                <button type="button" class="btn btn-darkgreen" @click="exportCsvUsers">
                  <i class="fa fa-file-excel-o pr5" />一覧CSVダウンロード
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm ref="modalConfirm">
      <div slot="header">
        <h4 class="modal-title">
          クライアント情報の削除
        </h4>
      </div>
      <div slot="content">
        <p>当該ユーザーを削除してよろしいですか？</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-darkgray" data-dismiss="modal">
          キャンセル
        </button>
        <button type="button" class="btn btn-darkred changeBT" data-dismiss="modal" @click="deleteUser">
          削除する
        </button>
      </div>
    </ModalConfirm>
    <button id="btn-toggle-modal" style="display: none" data-toggle="modal" data-target="#modal-confirm" />
  </main>
</template>

<script>
import ListUserTable from '../../components/admin/ListUserTable'
import ModalConfirm from '../../components/modal/ModalConfirm'
import { getUserDetailApi, deleteUserApi, exportCsvUsersApi } from '../../services/api'
import store from '../../store'
import { actions as actionsApp } from '../../store/modules/app'
import { exportMixin } from '../../shared/mixins/export'

export default {
  name: 'ListUser',
  components: { ListUserTable, ModalConfirm },
  mixins: [exportMixin],
  data() {
    return {
      userSelect: null,
      classW100: false
    }
  },
  created() {
    /* eslint-disable */
    const self = this;
    $(document).on('click', '.delete', function() {
      self.selectClient($(this).data('id'));
    });
  },
  watch: {
    classW100(value) {
      if (value) {
        $( ".dataTables_scrollHeadInner" ).addClass( "w100" );
      } else {
        $( ".dataTables_scrollHeadInner" ).removeClass( "w100" );
      }
    }
  },
  methods: {
    async selectClient(id) {
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await getUserDetailApi(id)
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        this.userSelect = res.data[0]
        document.getElementById('btn-toggle-modal').click()
      } else {
        this.$refs.listUser.refreshData();
        this.$toasted.error('アカウントは存在しません。')
      }
    },
    async deleteUser() {
      if (this.userSelect) {
        await store.dispatch(actionsApp.SET_LOADING, { active: true })
        const res = await deleteUserApi(this.userSelect.id)
        await store.dispatch(actionsApp.SET_LOADING, { active: false })
        if (res.status === 200) {
          this.$refs.listUser.refreshData();
          this.$toasted.success('削除に成功しました。')
        } else {
          this.$refs.listUser.refreshData();
          this.$toasted.error(res.data.message)
        }
      }
      this.$refs.modalConfirm.close()
    },
    async exportCsvUsers() {
      const keySearch = $('#clientList_filter .input-sm').val();
      const res = await exportCsvUsersApi({
        search: keySearch.trim()
      })
      if (res.status === 200) {
        this.exportCSVMixins('list_user', res.data)
      }
    }
  }
}
</script>

<style scoped>
</style>
