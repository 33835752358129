const dataTable = {
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      dataTable: '',
      config: {},
      clientList: null
    }
  },
  watch: {
    data(newVal) {
      this.updateData(newVal)
    },
    selectedValue() {
      /* eslint-disable */
      this.dataTable = $(this.$el).DataTable(this.config)
      $('.dataTables_filter input').attr('maxLength', 200)
      if (this.$el.id === 'clientList') {
        this.clientList = this.dataTable
      }
      this.updateData(this.data)
    }
  },
  mounted() {
    $.fn.dataTable.ext.errMode = 'None'
    this.dataTable = $(this.$el).DataTable(this.config)
    $('.dataTables_filter input').attr('maxLength', 200)
    this.updateData(this.data)
  },
  updated() {
    const vm = this
    if (this.$el.id === 'clientList') {
      $('#clientList_filter input')
        .off()
        .on('focusout', function (e) {
          vm.clientList.search(this.value).draw()
        })
    }
  },
  methods: {
    export(type) {
      if (type === 'csv') {
        this.dataTable.button('.buttons-csv').trigger()
      }
    },
    updateData(data) {
      this.dataTable.clear()
      this.dataTable.rows.add(data)
      this.dataTable.draw()
    },
    refreshData() {
      this.dataTable.clear()
      this.dataTable.draw()
    }
  }
}

export default dataTable
