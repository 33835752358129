<template>
  <table id="clientList" class="table table-striped display nowrap w100">
    <thead>
      <tr>
        <th>顧客ID</th>
        <th>会社名・支社名</th>
        <th>部署名</th>
        <th>住所</th>
        <th>電話番号</th>
        <th>ユーザー名(漢字)</th>
        <th>ユーザー名(ひらがな)</th>
        <th>メールアドレス</th>
        <th>登録日</th>
        <th>プラン開始日</th>
        <th>有効期限</th>
        <th>ステータス</th>
        <th>削除</th>
      </tr>
    </thead>
  </table>
</template>

<script>
import dataTable from '../../shared/mixins/dataTable'
import { dateFormatted } from '../../shared/common'

export default {
  name: 'ListUserTable',
  mixins: [dataTable],
  data() {
    return {
      config: {
        language: {
          'sProcessing': '処理中...',
          'sLengthMenu': '_MENU_ 件表示',
          'sZeroRecords': "<span style='color: #0f0f0f;'>データはありません。</span>",
          'sInfo': ' _TOTAL_ 件中 _START_ から _END_ まで表示',
          'sInfoEmpty': ' 0 件中 0 から 0 まで表示',
          'sInfoFiltered': '（全 _MAX_ 件より抽出）',
          'sInfoPostFix': '',
          'sSearch': '検索:',
          'sUrl': '',
          'oPaginate': {
            'sFirst': '先頭',
            'sPrevious': '前',
            'sNext': '次',
            'sLast': '最終'
          }
        },
        lengthMenu: [25, 50, 100],
        paging: true,
        serverSide: true,
        searchDelay: 500,
        ajax: {
          url: `${process.env.VUE_APP_API_URL}/api/users/`,
          type: 'GET',
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        },
        responsive: true,
        scrollX: true,
        columns: [
          { data: row => row.id },
          { data: row => row.organization_name, className: 'whiteSpaceBreak minW150' },
          { data: row => row.department_name, className: 'whiteSpaceBreak minW150' },
          { data: row => `${row.address} ${row.building_address || ''}`, className: 'whiteSpaceBreak minW150' },
          { data: row => row.phone_number, className: 'minW100' },
          { data: row => `${row.last_name} ${row.first_name}`, className: 'whiteSpaceBreak minW150' },
          { data: row => `${row.last_name_jp} ${row.first_name_jp}`, className: 'whiteSpaceBreak minW150' },
          { data: row => row.email, className: 'minW200' },
          { data: row => dateFormatted(row.date_joined), className: 'minW100' },
          { data: row => '', className: 'minW100' },
          { data: row => '', className: 'minW100' },
          { data: row => `<i class="fa fa-check-circle green pr5"></i>${row.is_active ? '有効' : '無効'}`, className: 'minW100' },
          {
            data: row => `<button type="button" data-id="${row.id}" class="btn-xs btn-darkred delete"><i class="fa fa-trash pr5"></i>削除</button>`, className: 'minW100'
          }
        ],
        columnDefs: [
          {
            targets: [9, 10, 11, 12],
            'orderable': false
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#clientList {
  width: 100% !important;
}
</style>
